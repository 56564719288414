/* eslint-disable react-hooks/exhaustive-deps */
import '../../assets/style.css';

import { Stack, Switch } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';

import Card from '@mui/material/Card';
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// Data
import { DataGrid } from '@mui/x-data-grid';
// @mui material components
import Grid from '@mui/material/Grid';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import UserService from 'services/user-service';
import { toast } from 'react-toastify';
import { AuthContext } from 'context';
import { Limit } from 'constants/urls';

export const useDebounce = (initialValue = '', delay) => {
  const [actualValue, setActualValue] = useState(initialValue);
  const [debounceValue, setDebounceValue] = useState(initialValue);
  useEffect(() => {
    const debounceId = setTimeout(() => setDebounceValue(actualValue), delay);
    return () => clearTimeout(debounceId);
  }, [actualValue, delay]);
  return [debounceValue, setActualValue];
};

const UserManagement = () => {
  const authContext = useContext(AuthContext);
  const [search, setSearch] = useDebounce('', 1000);
  const [totalResults, setTotalResults] = useState(0);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: Limit,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'desc',
    },
  ]);
  const [skip, setSkip] = useState(0);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      width: 240,
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      width: 400,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.status}
            onChange={() => changeActiveStatus(params.row)}
          />
        );
      },
    },
  ];

  useMemo(() => {
    (async () => {
      setLoading(true);
      try {
        await handleList();
      } catch (res) {
        res && toast.error(res?.message);
        setLoading(false);
      }
    })();
  }, [
    search,
    sortModel,
    skip,
    paginationModel.pageSize,
    authContext.loginToken,
  ]);

  async function handleList() {
    const url =
      sortModel[0].sort === 'asc'
        ? sortModel[0].field
        : `-${sortModel[0].field}`;
    const searchStr = search && `&search=${search}`;
    const res = await UserService.fetchUsers(
      `?sort=${url}${searchStr}&skip=${skip}&limit=${
        paginationModel.pageSize ?? Limit
      }`
    );
    const newData = await res?.data?.results.map((user) => {
      return {
        ...user,
        id: user?._id,
        status: user?.status === 'active' ? true : false,
      };
    });
    setUsers(newData);
    setPaginationModel({
      pageSize: res?.data?.limit ?? Limit,
      page: parseInt(res?.data?.page) - 1,
    });
    setTotalResults(res?.data?.totalResults);
    setLoading(false);
  }

  async function changeActiveStatus(data) {
    setLoading(true);
    const updateValue = { status: data.status ? 'inactive' : 'active' };
    try {
      const res = await UserService.updateStatus(data.id, updateValue);
      if (res.status === 200) {
        handleList();
        toast.success(res.message);
      }
    } catch (error) {
      if (error.hasOwnProperty('message')) toast.error(error.message);
      setLoading(false);
    }
  }

  function handleSkip(pageData) {
    if (pageData.page > paginationModel.page) {
      setSkip((preState) => parseInt(preState) + parseInt(pageData.pageSize));
    } else {
      setSkip((preState) =>
        parseInt(preState) > 0
          ? parseInt(preState) - parseInt(pageData.pageSize)
          : 0
      );
    }
  }

  function NoResultsOverlay() {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No results in DataGrid
        <pre>(rows=&#123;rowData&#125;)</pre>
        But local filter returns no result
      </Stack>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="black">
                  Users
                </MDTypography>
              </MDBox>
              <MDBox display="flex" pt={3} px={2}>
                <MDBox pr={1}>
                  <MDInput
                    label="Search here"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </MDBox>
              </MDBox>
              <MDBox p={3} px={2}>
                <DataGrid
                  className="data-list"
                  loading={loading}
                  localeText={{ noRowsLabel: 'User not found' }}
                  rows={users ?? []}
                  columns={columns}
                  components={{ norowsoverlay: NoResultsOverlay }}
                  initialState={{
                    pagination: {
                      paginationModel,
                    },
                  }}
                  filterMode="server"
                  sortingMode="server"
                  sortModel={sortModel}
                  onSortModelChange={(model) => {
                    setSortModel(model.length > 0 ? model : sortModel);
                  }}
                  paginationMode="server"
                  onPaginationModelChange={(model) => {
                    setPaginationModel(model);
                    handleSkip(model);
                  }}
                  rowCount={totalResults}
                  pageSizeOptions={[5, 10, 20]}
                  disableColumnMenu={true}
                  rowSelection={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
};

export default UserManagement;

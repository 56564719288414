/**
 * Front End Routes
 */

export const Login = "auth/login";
export const Dashboard = "dashboard";
export const User = "user";
export const User_Profile = "user-profile";
export const Subscription = "subscription";

/**
 * Backend API route
 */

/**
 * Auth
 */
export const Login_API = "v1/auth/login";
export const Logout_API = "v1/auth/logout";
export const Refresh_Token_API = "v1/auth/refresh-tokens";

/**
 * User module
 */
export const User_List_API = "v1/users";
export const Change_User_Status_API = "";
export const User_Profile_API = "";

/**
 * Subscription module (Free subscription)
 */
export const Subscription_List_API = 'v1/subscriptions';
export const Change_Subscription_Status_API = '';

/**
 * Pagination limit
 */
export const Limit = 10;

/* eslint-disable import/no-anonymous-default-export */
import { Login_API, Logout_API, Refresh_Token_API } from 'constants/urls';
import HttpService from './htttp.service';

class AuthService {
  // authEndpoint = process.env.API_URL;

  login = async (payload) => {
    const loginEndpoint = Login_API;
    return await HttpService.post(loginEndpoint, payload);
  };

  register = async (credentials) => {
    const registerEndpoint = 'register';
    return await HttpService.post(registerEndpoint, credentials);
  };

  logout = async (payload) => {
    const logoutEndpoint = Logout_API;
    return await HttpService.post(logoutEndpoint, payload);
  };

  loginAgainWithRefreshToken = async (payload) => {
    const loginAgainEndPoint = Refresh_Token_API;
    return await HttpService.post(loginAgainEndPoint, payload);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = 'password-forgot';
    return await HttpService.post(forgotPassword, payload);
  };

  resetPassword = async (credentials) => {
    const resetPassword = 'password-reset';
    return await HttpService.post(resetPassword, credentials);
  };

  getProfile = async () => {
    const getProfile = 'me';
    return await HttpService.get(getProfile);
  };

  updateProfile = async (newInfo) => {
    const updateProfile = 'me';
    return await HttpService.patch(updateProfile, newInfo);
  };

  fetchUsers = async () => {
    const fetchUsers = 'users';
    return await HttpService.patch(fetchUsers);
  };

  fetchSubscription = async () => {
    const fetchSubscription = 'subscription';
    return await HttpService.patch(fetchSubscription);
  };
}

export default new AuthService();

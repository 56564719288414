/* eslint-disable import/no-anonymous-default-export */
import HttpService from './htttp.service';
import { User_List_API } from 'constants/urls';

class UserService {
  fetchUsers = async (url = '') => {
    const fetchUsers = `${User_List_API}${url}`;
    return await HttpService.get(fetchUsers);
  };

  updateStatus = async (id, newInfo) => {
    const updateStatus = `${User_List_API}/${id}`;
    return await HttpService.patch(updateStatus, newInfo);
  };
}

export default new UserService();

import Moment from 'moment';

export const formatTimeDifference = (date, isExpireDate = false) => {
  return Moment(date).format("MMM DD YYYY, HH:mm")
  /* console.log(date);
  const now = Moment();
  const targetDate = Moment(date);
  const duration = Moment.duration(targetDate.diff(now));
  const seconds = duration.asSeconds();
  const minutes = duration.asMinutes();
  const hours = duration.asHours();
  const days = duration.asDays();
  const months = duration.asMonths();
  const years = duration.asYears();

  const timeFormats = [
    { limit: 60, single: 'second', plural: 'seconds', measure: seconds },
    { limit: 60, single: 'minute', plural: 'minutes', measure: minutes },
    { limit: 24, single: 'hour', plural: 'hours', measure: hours },
    { limit: 7, single: 'day', plural: 'days', measure: days },
    { limit: 12, single: 'month', plural: 'months', measure: months },
    { limit: Infinity, single: 'year', plural: 'years', measure: years },
  ];

  for (let { limit, single, plural, measure } of timeFormats) {
    const absMeasure = Math.abs(measure);
    if (absMeasure < limit) {
      const count = Math.floor(absMeasure);
      const timeUnit = count === 1 ? single : plural;
      const prefix = measure < 0 ? '' : 'in ';
      const suffix = measure < 0 ? ' ago' : '';
      if (count === 0 && timeUnit === 'seconds') {
        return measure < 0 ? 'a few seconds ago' : 'in a few seconds';
      }
      return `${prefix}${count} ${timeUnit}${suffix}`;
    }
  } */
};
export const formatTimestamp = (timestamp) => {
  const date = Moment(timestamp);
  // const now = Moment();

  // const differenceInMinutes = now.diff(date, 'minutes');

  // if (differenceInMinutes < 1) {
  //   return 'just now';
  // } else if (differenceInMinutes < 60) {
  //   return `${differenceInMinutes} minutes ago`;
  // } else if (differenceInMinutes < 1440) {
  //   return `${Math.floor(differenceInMinutes / 60)} hours ago`;
  // } else {
  //   return date.format('MMM DD YYYY, HH:mm');
  // }
  return date.format('MMM DD YYYY, HH:mm');
};

export const formatTimestampExpired = (targetDate) => {
  // const now = Moment(Moment().toISOString());
  const target = Moment(targetDate);

  // const duration = Moment.duration(target.diff(now));

  // const years = duration.years();
  // const months = duration.months();
  // const days = duration.days();
  // const hours = duration.hours();

  // let result = '';
  // if (years > 0) result += `in ${years} year${years > 1 ? 's' : ''}, `;
  // else if (months > 0) result += `in ${months} month${months > 1 ? 's' : ''}, `;
  // else if (days > 0) result += `in ${days} day${days > 1 ? 's' : ''}, `;
  // else if (hours > 0) result += `in ${hours} hour${hours > 1 ? 's' : ''}`;
  // else result += target.format('MMM DD YYYY, HH:mm');
  // return result.replace(/,\s*$/, ''); // Remove trailing comma and space
  return target.format('MMM DD YYYY, HH:mm');
};

// export const formatTimestampExpired = (timestamp) => {
//   const date = Moment(timestamp, 'DD-MM-YYYY h:mm A');
//   console.log('date: ', date);
//   const now = Moment();
//   console.log('now: ', now);

//   const years = date.diff(now, 'years');
//   now.add(years, 'years');

//   const months = date.diff(now, 'months');
//   now.add(months, 'months');

//   const days = date.diff(now, 'days');
//   now.add(days, 'days');

//   const hours = date.diff(now, 'hours');
//   now.add(hours, 'hours');

//   const minutes = date.diff(now, 'minutes');

//   if (minutes > 0) {
//     return `in ${minutes} minute${minutes > 1 ? 's' : ''}`;
//   } else if (hours > 0) {
//     return `in ${hours} hour${hours > 1 ? 's' : ''}`;
//   } else if (days > 0) {
//     return `in ${days} day${days > 1 ? 's' : ''}`;
//   } else if (months > 0) {
//     return `in ${months} month${months > 1 ? 's' : ''}`;
//   } else if (years > 0) {
//     return `in ${years} year${years > 1 ? 's' : ''}`;
//   } else {
//     return date.format('MMM DD YYYY, HH:mm');
//   }
// };

export default formatTimestamp;

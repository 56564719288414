import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';

import { AuthContext } from 'context';
import AuthService from 'services/auth-service';
// Authentication layout components
import BasicLayoutLanding from 'layouts/authentication/components/BasicLayoutLanding';
// @mui material components
import Card from '@mui/material/Card';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';
import { toast } from 'react-toastify';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Invalid email')
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      'Enter valid email',
    ),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

function Login() {
  const authContext = useContext(AuthContext);

  const [user, setUser] = useState({});

  const addUserHandler = (newUser) => setUser(newUser);

  return (
    <BasicLayoutLanding image={bgImage}>
      <Card>
        <MDBox
          color='light'
          variant='gradient'
          bgColor='warning'
          borderRadius='lg'
          coloredShadow='warning'
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign='center'
        >
          <MDTypography variant='h4' fontWeight='medium' color='black'>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={{
              password: '',
              email: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={async (values) => {
              // same shape as initial values
              addUserHandler(values);
              try {
                const response = await AuthService.login(values);
                const tokens = response?.data?.tokens;
                authContext.login(
                  tokens?.access?.token,
                  tokens?.refresh?.token,
                );
                toast.success(response.message);
              } catch (res) {
                if (res.hasOwnProperty('message')) toast.error(res.message);
              }
            }}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <MDBox mb={2}>
                  <MDInput
                    type='email'
                    label='Email'
                    fullWidth
                    value={values.email}
                    name='email'
                    onChange={handleChange}
                    error={errors.emailError}
                  />
                  {errors.email && touched.email ? (
                    <MDTypography
                      variant='caption'
                      color='error'
                      fontWeight='light'
                    >
                      {errors.email}
                    </MDTypography>
                  ) : null}
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type='password'
                    label='Password'
                    fullWidth
                    name='password'
                    value={values.password}
                    onChange={handleChange}
                    error={errors.passwordError}
                  />
                  {errors.password && touched.password ? (
                    <MDTypography
                      variant='caption'
                      color='error'
                      fontWeight='light'
                    >
                      {errors.password}
                    </MDTypography>
                  ) : null}
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant='gradient'
                    color='dark'
                    fullWidth
                    type='submit'
                  >
                    sign in
                  </MDButton>
                </MDBox>
              </Form>
            )}
          </Formik>
        </MDBox>
      </Card>
    </BasicLayoutLanding>
  );
}

export default Login;

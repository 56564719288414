import { User } from "constants/urls";
import { Login } from "constants/urls";
import { Navigate } from "react-router-dom";

export const AuthProtectedRoute = ({ isAuthenticated, redirectPath = '/' + User, children }) => {
  if (isAuthenticated) {
    return <Navigate to={redirectPath} />;
  }

  return children;
};

const ProtectedRoute = ({ isAuthenticated, redirectPath = '/' + Login, children }) => {
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;

import { Refresh_Token_API } from "constants/urls";
import HttpService from "./htttp.service";

const API_URL = process.env.REACT_APP_API_URL;

let isRefreshing = false;

const refreshAccessToken = async (refreshToken, manageContext) => {
  try {
    const response = await HttpService.post(`${API_URL}${Refresh_Token_API}`, {
      refreshToken,
    });
    const newAccessToken = response?.data?.access?.token ?? '';
    const newRefreshToken = response?.data?.refresh?.token ?? '';

    localStorage.setItem('token', newAccessToken); // Update the access token in local storage
    localStorage.setItem('refresh', newRefreshToken); // Update the access token in local storage

    manageContext.login(newAccessToken, newRefreshToken);
    isRefreshing = false;
    return newAccessToken;
  } catch (error) {
    isRefreshing = true;
    throw error; // You can handle errors in the calling code
  }
};

export const setupAxiosInterceptors = (onUnauthenticated, updateContext) => {
  const onRequestSuccess = async (config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  };
  const onRequestFail = (error) => Promise.reject(error);

  HttpService.addRequestInterceptor(onRequestSuccess, onRequestFail);

  const onResponseSuccess = (response) => response;

  const onResponseFail = async (error) => {
    const status = error.status || (error.response && error.response.status);

    // if (status === 403 || status === 401) {
    if ((status === 403 || status === 401) && !isRefreshing) {
      isRefreshing = true;
      try {
        // Attempt to refresh the access token
        const refreshToken = localStorage.getItem('refresh');
        if (refreshToken) {
          const newAccessToken = await refreshAccessToken(
            refreshToken,
            updateContext
          );
          const config = error.config;
          config.headers.Authorization = `Bearer ${newAccessToken}`;
          // Retry the failed request with the new access token
          return HttpService.request(config);
        } else {
          updateContext.logout();
          onUnauthenticated();
          return Promise.reject(error);
        }
      } catch (refreshError) {
        localStorage.clear();
        updateContext.logout();
        onUnauthenticated();
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false; // Ensure the flag is reset in the finally block
      }
    } else if (isRefreshing) {
      updateContext.logout();
      onUnauthenticated();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };
  HttpService.addResponseInterceptor(onResponseSuccess, onResponseFail);
};
